import { useState } from "react";
import "./Login.css";
import logo from "../assets/logo.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

export default function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const login = () => {
    setError("");
    if (email == "" && password == "") {
      setError("Please Fill out both fields");
    } else {
      console.log(email, password);
      let obj = {
        email: email,
        password: password,
      };
      console.log(REACT_APP_API_URL);
      axios
        .post(`${REACT_APP_API_URL}/auth/login`, obj)
        .then((resp) => {
          console.log("resp", resp);
          localStorage.setItem("auth-token", resp.data.token);
          delete resp.data.token;
          delete resp.data.password;
          localStorage.setItem("user", JSON.stringify(resp.data));
          localStorage.setItem("role", resp.data.role);
          localStorage.setItem("contractaddress", resp.data.contractaddress);
          localStorage.setItem("abi", resp.data.abi);
          history.push("/dashboard");
        })
        .catch((error) => {
          setError("Incorrect username or password.");
          console.log("error", error);
        });
    }
  };

  return (
    <div className="login-container">
      <div className="flex justify-center">
        <div className="rounded-lg shadow-lg bg-white max-w-md">
          <a data-mdb-ripple="true" data-mdb-ripple-color="light">
            <img className="rounded-t-lg p-10" src={logo} alt="" />
          </a>
          <div className="p-6 ">
            <div className="flex justify-center">
              <div>
                <div className="form-floating mb-3 xl:w-96">
                  <input
                    type="email"
                    className="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="floatingInput"
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput" className="text-gray-700">
                    Email address
                  </label>
                </div>
                <div className="form-floating mb-3 xl:w-96">
                  <input
                    type="password"
                    className="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="floatingPassword"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="floatingPassword" className="text-gray-700">
                    Password
                  </label>
                  <p className="text-red-500 pt-2 text-center">{error}</p>
                </div>

                <div className="mb-3 xl:w-96 flex">
                  <button
                    type="button"
                    className="mb-2 w-full inline-block px-6 py-2.5 bg-purple-900 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => login()}
                  >
                    Login
                  </button>
                </div>
                <div className="xl:w-96 flex">
                  <span className="text-blue-500 text-center w-full">
                    <a
                      className="pb-5"
                      onClick={() => history.push("/resetpasswordrequest")}
                    >
                      Forgot Password?
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
