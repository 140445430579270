import { useEffect, useState } from "react";
import axios from "axios";
import S3FileUpload from "react-s3";
import { useHistory, useParams } from "react-router-dom";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

export default function AdminEditUser() {
  let { id } = useParams();
  const history = useHistory();

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [companyLine1, setCompanyLine1] = useState("");
  const [companyLine2, setCompanyLine2] = useState("");
  const [companyLine3, setCompanyLine3] = useState("");
  const [postCode, setPostCode] = useState("");
  const [logo, setLogo] = useState(null);
  const [summary, setSummary] = useState("");
  const [plan, setPlan] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    let authToken = localStorage.getItem("auth-token");
    axios
      .get(`${REACT_APP_API_URL}/auth/getUserData/${id}`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resp) => {
        console.log(resp.data, "USER DATA");
        setUser(resp.data);
        setName(resp.data.name);
        setCompanyName(resp.data.companyname);
        setTelephone(resp.data.telephone);
        setCompanyLine1(resp.data.companyaddress1);
        setCompanyLine2(resp.data.companyaddress2);
        setCompanyLine3(resp.data.companyaddress3);
        setPostCode(resp.data.postcode);
        setSummary(resp.data.summary);
        setPlan(resp.data.plan);
      });
  }, []);

  async function updateUser() {
    setSuccess("");
    setError("");
    if (
      name == "" ||
      companyName == "" ||
      companyLine1 == "" ||
      postCode == ""
    ) {
      setError("Please fill all fields");
    } else {
      let image;
      if (logo != null) {
        image = await uploadToS3(logo);
        image = image.location;
      } else {
        image = user.logo;
      }
      let obj = {
        email: user.email,
        name: name,
        companyname: companyName,
        telephone: telephone,
        companyaddress1: companyLine1,
        companyaddress2: companyLine2,
        companyaddress3: companyLine3,
        postcode: postCode,
        logo: image,
        summary: summary,
        plan: plan,
      };
      let authToken = localStorage.getItem("auth-token");
      axios
        .post(`${REACT_APP_API_URL}/auth/AdminEditUser`, obj, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((resp) => {
          history.push("/dashboard/users");
          console.log("resp", resp);
        })
        .catch((error) => {
          setError("An Error Occured");
          console.log("error", error);
        });
    }
  }

  function imageInput(event) {
    console.log(event.target.files[0]);
    setLogo(event.target.files[0]);
  }
  function uploadToS3(file) {
    const config = {
      bucketName: "houndstooth-app",
      dirName: "files",
      region: "eu-west-2",
      accessKeyId: "AKIA5SWLXHYBSC3YZFUY",
      secretAccessKey: "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",
    };
    return S3FileUpload.uploadFile(file, config);
  }

  return (
    <>
      <div className="px-10 container mt-12">
        <h1 className="text-3xl font-medium text-primary">Edit User</h1>

        <div className="grid grid-cols-1 w-7/12 mt-5">
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Name
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Name
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Telephone
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Line Address 1
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={companyLine1}
              onChange={(e) => setCompanyLine1(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Line Address 2
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={companyLine2}
              onChange={(e) => setCompanyLine2(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Line Address 3
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={companyLine3}
              onChange={(e) => setCompanyLine3(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Postalcode
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Summary
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-1  mb-5 border">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Logo
            </label>
            <input
              className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="user_avatar_help"
              id="user_avatar"
              type="file"
              onChange={(event) => imageInput(event)}
            />
          </div>
          <div class="flex justify-start">
            <div class="mb-3 xl:w-96">
              <select
                class="form-select appearance-none
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                value={plan}
                onChange={(e) => setPlan(e.target.value)}
              >
                <option value="0" selected>
                  Select User Plan*
                </option>
                <option value="1">Plan One (1-200 NFT month limit)</option>
                <option value="2">Plan Two (201- 500 NFT month limit)</option>
                <option value="3">
                  Plan Three (501 - Unlimited NFT per month)
                </option>
              </select>
            </div>
          </div>
        </div>
        <span className="text-red-500 text-xl">{error}</span>
        <span className="text-green-700 text-xl">{success}</span>
        <div className="grid grid-cols-1 w-7/12 mt-5 border">
          <button
            onClick={() => updateUser()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
          >
            Update Profile
          </button>
        </div>
        <div className="grid grid-cols-1 w-7/12 mt-5 border"></div>
      </div>
    </>
  );
}
