import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CsvDownload from "react-json-to-csv";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;
const UserList = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    let authToken = localStorage.getItem("auth-token");
    axios
      .get(`${REACT_APP_API_URL}/auth/getStaffs`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resp) => {
        console.log("resp", resp);
        setUsers(resp.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  async function deactivateUser(id) {
    let authToken = localStorage.getItem("auth-token");
    axios
      .get(`${REACT_APP_API_URL}/auth/deactivate/${id}`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resp) => {
        console.log("resp", resp);
        getUsers();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function activateUser(id) {
    let authToken = localStorage.getItem("auth-token");
    axios
      .get(`${REACT_APP_API_URL}/auth/activate/${id}`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resp) => {
        console.log("resp", resp);
        getUsers();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  return (
    <>
      <div className="px-10 container mt-12">
        <div className="flex  items-center justify-between">
          <h1 className="text-3xl font-medium text-primary">
            Staff Members List
          </h1>
          <div>
            <CsvDownload
              className="bg-primary hover:bg-purple-800 text-white px-4 py-2 rounded-md mr-5"
              data={users}
            />
            <button
              onClick={() => history.push("/dashboard/staff/create")}
              className="bg-primary hover:bg-purple-800 text-white px-4 py-2 rounded-md"
            >
              Create Staff Member
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 mt-5">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow-md sm:rounded-lg">
                  <table className="min-w-full">
                    <thead className="text-white bg-primary dark:bg-gray-700">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 px-6 font-medium  text-left text-white capitalize dark:text-gray-400"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6  font-medium  text-left text-white capitalize dark:text-gray-400"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6  font-medium  text-left text-white capitalize dark:text-gray-400"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="py-3 px-6  font-medium  text-left text-white capitalize dark:text-gray-400"
                        >
                          Status
                        </th>
                        <th scope="col" className="relative py-3 px-6">
                          <span className="sr-only">Edit</span>
                        </th>
                        <th scope="col" className="relative py-3 px-6">
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {user.email}
                          </td>
                          <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {user.name}
                          </td>
                          <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                            Staff Member
                          </td>
                          <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {user.active ? "Active" : "Deactivated"}
                          </td>
                          <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              onClick={() =>
                                history.push(`/dashboard/staff/edit/${user.id}`)
                              }
                              className="text-primary hover:text-gray-900 dark:text-blue-500 dark:hover:underline"
                            >
                              Edit
                            </a>
                          </td>
                          <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                            {user.active ? (
                              <a
                                onClick={() => deactivateUser(user.id)}
                                href="#"
                                className="text-primary hover:text-gray-90 dark:text-blue-500 dark:hover:underline"
                              >
                                Deactivate user
                              </a>
                            ) : (
                              <a
                                onClick={() => activateUser(user.id)}
                                href="#"
                                className="text-primary hover:text-gray-90 dark:text-blue-500 dark:hover:underline"
                              >
                                Activate user
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
