import React, { useEffect, useState } from "react";
import "./Create.css";
import axios from "axios";
import Web3 from "web3";
import S3FileUpload from "react-s3";
import { useHistory } from "react-router-dom";
require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

export default function Create() {
  const history = useHistory();

  const [user, setUser] = useState();
  const [name, setName] = useState("");
  const [volume, setVolume] = useState(0);
  const [description, setDescription] = useState("");
  const [nft, setNft] = useState(null);
  const [gif, setGif] = useState(null);
  const [objLow, setObjLow] = useState(null);
  const [objHigh, setObjHigh] = useState(null);
  const [gltfHigh, setGltfHigh] = useState(null);
  const [gltfLow, setGltfLow] = useState(null);
  const [noAttributes, setNoAttributes] = useState(0);
  const [attributes, setAttributes] = useState([]);
  const [contract, setContract] = useState(null);
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contractInfo, setContractInfo] = useState();
  const [remaining, setRemaining] = useState(0);

  useEffect(async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    console.log(user);
    axios
      .get(`${REACT_APP_API_URL}/nft/get_contract_of_owner/${user.id}`)
      .then((resp) => {
        setContractInfo(resp.data);
        loadWeb3(resp.data);
      });
    axios.get(`${REACT_APP_API_URL}/nft/checkLimit/${user.id}`).then((resp) => {
      console.log(resp.data.remaining);
      setRemaining(resp.data.remaining);
    });
  }, []);

  async function loadWeb3(info) {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      // await window.ethereum.enable();
      const web3 = window.web3;
      // creating contract instance
      const contractaddress = info.address;
      const abi = JSON.parse(info.abi);
      const ct = new web3.eth.Contract(abi, contractaddress);
      console.log(ct);
      setContract(ct);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  const submit = async () => {
    if (parseInt(volume) > remaining) {
      alert("Please check limit or upgrade your plan");
      return;
    }
    if (
      gif != null &&
      objLow != null &&
      objHigh != null &&
      gltfHigh != null &&
      gltfLow != null &&
      nft != null &&
      name != "" &&
      volume != 0 &&
      description != "" &&
      attributes != []
    ) {
      var body = new FormData();
      body.append("photos", nft);
      body.append("name", name);
      body.append("volume", volume);
      body.append("description", description);
      body.append("attributes", JSON.stringify(attributes));
      body.append("ownerid", user.id);
      body.append("contractid", contractInfo.id);
      setLoading(true);
      let gifResponse = await uploadToS3(gif);
      let objLowResponse = await uploadToS3(objLow);
      let objHighResponse = await uploadToS3(objHigh);
      let gltfHighResponse = await uploadToS3(gltfHigh);
      let gltfLowResponse = await uploadToS3(gltfLow);
      body.append("gif", gifResponse.location);
      body.append("objlow", objLowResponse.location);
      body.append("objhigh", objHighResponse.location);
      body.append("gltfhigh", gltfHighResponse.location);
      body.append("gltfLow", gltfLowResponse.location);
      let authToken = localStorage.getItem("auth-token");
      axios
        .post(`${REACT_APP_API_URL}/nft/add`, body, {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": authToken,
          },
          onUploadProgress: (progressEvent) =>
            console.log(progressEvent.loaded),
        })
        .then(async (resp) => {
          console.log(resp);
          const web3 = window.web3;
          const address = await web3.eth.getAccounts();
          await contract.methods
            .addNewCollection(name, parseInt(volume), description)
            .send({
              from: address.toString(),
            });
          history.push("/dashboard/existing");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      alert("please fill all fields");
    }
  };

  async function connect() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      setConnected(true);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  function imageInput(event) {
    console.log(event.target.files[0]);
    setNft(event.target.files[0]);
  }
  function gifInput(event) {
    console.log(event.target.files[0]);
    setGif(event.target.files[0]);
  }
  function objLowInput(event) {
    console.log(event.target.files[0]);
    setObjLow(event.target.files[0]);
  }
  function objHighInput(event) {
    console.log(event.target.files[0]);
    setObjHigh(event.target.files[0]);
  }
  function gltfHighInput(event) {
    console.log(event.target.files[0]);
    setGltfHigh(event.target.files[0]);
  }
  function gltfLowInput(event) {
    console.log(event.target.files[0]);
    setGltfLow(event.target.files[0]);
  }
  function uploadToS3(file) {
    const config = {
      bucketName: "houndstooth-app",
      dirName: "files",
      region: "eu-west-2",
      accessKeyId: "AKIA5SWLXHYBSC3YZFUY",
      secretAccessKey: "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",
    };
    return S3FileUpload.uploadFile(file, config);
  }

  return (
    <div className="px-10 container mt-12">
      <h1 className="text-3xl font-medium text-primary">
        Create Collection (Remaining Limit {remaining})
      </h1>
      <div className="grid grid-cols-1 w-7/12 mt-3">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control
                    block
                    w-full
                    px-3
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded-lg
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
            id="collectionName"
            placeholder="name@example.com"
            onChange={(e) => setName(e.target.value)}
          />
          <label for="collectionName" className="text-gray-700">
            Collection Name
          </label>
        </div>
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control
                    block
                    w-full
                    px-3
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded-lg
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
            id="collectionName"
            placeholder="name@example.com"
            onChange={(e) => setVolume(e.target.value)}
          />
          <label for="collectionName" className="text-gray-700">
            NFT Volume
          </label>
        </div>
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3">
        <label className="mb-5">
          <select
            className=" form-control rounded-lg"
            onChange={(e) => setNoAttributes(parseInt(e.target.value))}
          >
            <option value="0">Select No of Attributes</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </label>
        <ul>
          {Array.from(Array(noAttributes), (e, i) => {
            return (
              <div key={i} className="row list-group-flush">
                <label className="col-5 has-float-label my-4 mx-3 ">
                  <input
                    type="text"
                    className="form-control rounded-lg"
                    onChange={(e) => {
                      let att = attributes;
                      console.log(att);
                      let obj = {
                        name: e.target.value,
                        value: att[i]?.value == null ? "" : att[i].value,
                      };
                      att[i] = obj;
                      setAttributes(att);
                      console.log(att);
                    }}
                  />
                  <span>Trait Type</span>
                </label>
                <label className="col-5 has-float-label mb-4  rounded-0">
                  <input
                    type="text"
                    className="form-control rounded-lg mt-3"
                    onChange={(e) => {
                      let att = attributes;
                      console.log(att);
                      let obj = {
                        name: att[i]?.name == null ? "" : att[i].name,
                        value: e.target.value,
                      };
                      att[i] = obj;
                      setAttributes(att);
                      console.log(att);
                    }}
                  />
                  <span>Value</span>
                </label>
              </div>
            );
          })}
        </ul>
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-1 ">
        <label for="message" className="text-gray-700 description mb-2">
          Collection Description
        </label>
        <div className="form-floating mb-3">
          <textarea
            id="message"
            rows="4"
            className="
                    block
                    w-full
                    px-3
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded-lg
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-400
                    focus:outline-none"
            // placeholder="Collection Description"
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-1 border">
        <label
          className="block mb-1 px-2  text-sm font-medium text-gray-700 dark:text-gray-300"
          for="user_avatar"
        >
          NFT Image
        </label>
        <input
          className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="user_avatar_help"
          id="user_avatar"
          type="file"
          onChange={(event) => imageInput(event)}
        />
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3 border">
        <label
          className="block m1-2 px-2  text-sm font-medium text-gray-700 dark:text-gray-300"
          for="user_avatar"
        >
          GIF
        </label>
        <input
          className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="user_avatar_help"
          id="user_avatar"
          type="file"
          onChange={(event) => gifInput(event)}
        />
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3 border">
        <label
          className="block m1-2 px-2  text-sm font-medium text-gray-700 dark:text-gray-300"
          for="user_avatar"
        >
          Obj Low Res File
        </label>
        <input
          className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="user_avatar_help"
          id="user_avatar"
          type="file"
          onChange={(event) => objLowInput(event)}
        />
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3 border">
        <label
          className="block m1-2 px-2  text-sm font-medium text-gray-700 dark:text-gray-300"
          for="user_avatar"
        >
          Obj High Res File
        </label>
        <input
          className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="user_avatar_help"
          id="user_avatar"
          type="file"
          onChange={(event) => objHighInput(event)}
        />
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3 border">
        <label
          className="block m1-2 px-2  text-sm font-medium text-gray-700 dark:text-gray-300"
          for="user_avatar"
        >
          GLB Low Res File
        </label>
        <input
          className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="user_avatar_help"
          id="user_avatar"
          type="file"
          onChange={(event) => gltfLowInput(event)}
        />
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3 border">
        <label
          className="block m1-2 px-2  text-sm font-medium text-gray-700 dark:text-gray-300"
          for="user_avatar"
        >
          GLB High Res File
        </label>
        <input
          className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="user_avatar_help"
          id="user_avatar"
          type="file"
          onChange={(event) => gltfHighInput(event)}
        />
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3 border">
        {!connected ? (
          <button
            onClick={() => connect()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
          >
            Connect Wallet
          </button>
        ) : (
          <button
            onClick={() => submit()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
          >
            Create Collection{" "}
            {loading ? (
              <div class="flex justify-center items-center">
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : null}
          </button>
        )}
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-3 border"></div>
      <br />
      <br />
      <br />
    </div>
  );
}
