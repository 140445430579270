import React, { useEffect, useState } from "react";
import axios from "axios";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

const CreateStaff = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  async function createUser() {
    setSuccess("");
    setError("");
    if (name == "" || email == "" || password == "" || confirmPassword == "") {
      setError("Please fill all fields");
    } else if (password != confirmPassword) {
      setError("Password did not match");
    } else {
      let obj = {
        email: email,
        password: password,
        name: name,
      };
      let authToken = localStorage.getItem("auth-token");
      axios
        .post(`${REACT_APP_API_URL}/auth/createStaff`, obj, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((resp) => {
          setName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          console.log("resp", resp);
          setSuccess("User Created");
        })
        .catch((error) => {
          setError("Email already exists");
          console.log("error", error);
        });
    }
  }

  return (
    <>
      <div className="px-10 container mt-12">
        <h1 className="text-3xl font-medium text-primary">Create Staff</h1>

        <div className="grid grid-cols-1 w-7/12 mt-5">
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Name
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              User Email
            </label>
            <input
              type="email"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Password
            </label>
            <input
              type="password"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
        <span className="text-red-500 text-xl">{error}</span>
        <span className="text-green-700 text-xl">{success}</span>
        <div className="grid grid-cols-1 w-7/12 mt-5 border">
          <button
            onClick={() => createUser()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
          >
            Create User
          </button>
        </div>
        <div className="grid grid-cols-1 w-7/12 mt-5 border"></div>
      </div>
    </>
  );
};

export default CreateStaff;
