import React, { useEffect, useState } from "react";
import axios from "axios";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

const ChangePassword = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  async function updatePassword() {
    setSuccess("");
    setError("");
    if (oldPassword == "" || password == "" || confirmPassword == "") {
      setError("Please fill all fields");
    } else if (password != confirmPassword) {
      setError("Password did not match");
    } else {
      let obj = {
        email: user.email,
        oldpassword: oldPassword,
        password: password,
      };
      let authToken = localStorage.getItem("auth-token");
      axios
        .post(`${REACT_APP_API_URL}/auth/changePassword`, obj, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((resp) => {
          setOldPassword("");
          setPassword("");
          setConfirmPassword("");
          console.log("resp", resp);
          setSuccess("Password Updated");
        })
        .catch((error) => {
          setError("Incorrect Password");
          console.log("error", error);
        });
    }
  }

  return (
    <>
      <div className="px-10 container mt-12">
        <h1 className="text-3xl font-medium text-primary">Change Password</h1>

        <div className="grid grid-cols-1 w-7/12 mt-5">
          <div class="mb-2">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Old Password
            </label>
            <input
              type="password"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div class="mb-2">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Password
            </label>
            <input
              type="password"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div class="mb-2">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
        <span className="text-red-500 text-xl">{error}</span>
        <span className="text-green-700 text-xl">{success}</span>
        <div className="grid grid-cols-1 w-7/12 mt-5 border">
          <button
            onClick={() => updatePassword()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-purple-800"
          >
            Update Password
          </button>
        </div>
        <div className="grid grid-cols-1 w-7/12 mt-5 border"></div>
      </div>
    </>
  );
};

export default ChangePassword;
