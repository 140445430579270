import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import DashboardLayout from "./layouts/DashboardLayout";
import Create from "./pages/Create/Create";
import Existing from "./pages/Existing/Existing";
import Nftlist from "./pages/Nftlist";
import ClaimNFT from "./pages/ClaimNFT";
import CreateUser from "./pages/Users/CreateUser";
import UserList from "./pages/Users/UserList";
import CreateStaff from "./pages/Staff/CreateStaff";
import StaffList from "./pages/Staff/StaffList";
import ContractDetails from "./pages/ContractDetails";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Notactive from "./pages/Notactive";
import Setting from "./pages/Setting";
import EditProfile from "./pages/Users/EditProfile";
import ChangePassword from "./pages/ChangePassword";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import ResetPassword from "./pages/ResetPassword";
import AdminEditUser from "./pages/Users/AdminEditUser";
import AdminEditStaff from "./pages/Staff/AdminEditStaff";
import ContractCreator from "./pages/ContractCreator";

function App() {
  useEffect(() => {
    if (window.location.host == "nft.sedgewear.com") {
      document.title = "SedgeShorts";
      document.getElementsByTagName("META")[2].content =
        "SedgeShorts NFT Collection";
      document.getElementById(
        "favicon-link"
      ).href = `https://${window.location.host}/sedge_favicon.ico`;
      document.getElementById(
        "apple-link"
      ).href = `https://${window.location.host}/sedge_logo512.png`;
    } else {
      document.title = "Houndstooth";
      document.getElementsByTagName("META")[2].content =
        "Houndstooth NFT Collection";
      document.getElementById(
        "favicon-link"
      ).href = `https://${window.location.host}/houndstooth_favicon.ico`;
      document.getElementById(
        "apple-link"
      ).href = `https://${window.location.host}/houndstooth_logo512.png`;
    }
  });
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true}>
            <Login />
          </Route>
          <Route path="/resetpasswordrequest" exact={true}>
            <ResetPasswordRequest />
          </Route>
          <Route path="/reset/:id/:token+">
            <ResetPassword />
          </Route>
          <ProtectedRoute path="/dashboard" exact={true}>
            <DashboardLayout>
              <Home />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/contract" exact={true}>
            <DashboardLayout>
              <ContractDetails />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/contractCreator" exact={true}>
            <DashboardLayout>
              <ContractCreator />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/settings" exact={true}>
            <DashboardLayout>
              <Setting />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/edit" exact={true}>
            <DashboardLayout>
              <EditProfile />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/update-password" exact={true}>
            <DashboardLayout>
              <ChangePassword />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/create" exact={true}>
            <DashboardLayout>
              <Create />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/existing" exact={true}>
            <DashboardLayout>
              <Existing />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/existing/nft/:id" exact={true}>
            <DashboardLayout>
              <Nftlist />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/users" exact={true}>
            <DashboardLayout>
              <UserList />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/users/create" exact={true}>
            <DashboardLayout>
              <CreateUser />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/users/edit/:id" exact={true}>
            <DashboardLayout>
              <AdminEditUser />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/staff/edit/:id" exact={true}>
            <DashboardLayout>
              <AdminEditStaff />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/staff" exact={true}>
            <DashboardLayout>
              <StaffList />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/staff/create" exact={true}>
            <DashboardLayout>
              <CreateStaff />
            </DashboardLayout>
          </ProtectedRoute>

          <ProtectedRoute path="/dashboard/notactive" exact={true}>
            <DashboardLayout>
              <Notactive />
            </DashboardLayout>
          </ProtectedRoute>

          <Route path="/claim/:id" exact={true}>
            <ClaimNFT />
          </Route>

          <Route path="*">
            <h1>404</h1>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
