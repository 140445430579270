import { useEffect } from "react";
import "./SedgewearClaim.css";
import logo from "../../assets/sedge-logo.png";
import logoWhite from "../../assets/sedge-logo-white.png";
export default function SedgewearClaim({
  nft,
  connect,
  claimNFT,
  connected,
  connectedWallet,
}) {
  useEffect(() => {
    // document.title = "Sedgewear | Claim";
    // document.getElementsByTagName("META")[2].content =
    //   "Claim your sedgewear NFT";
    // document.getElementsByTagName("link")[0].href =
    //   "%PUBLIC_URL%/sedge_favicon.ico";
  }, []);
  return (
    <div className=" bg-gray-100">
      <div className="w-full shadow-sm bg-white mb-10">
        <div className="nav-container flex flex-col items-center md:flex-row md:justify-between	">
          <img src={logo} className="w-4/12 md:w-2/12 py-5" />
          <div className="flex flex-col md:flex-row items-center gap-4 text-sedgewear ">
            <p>Home</p>
            <p>Instagram</p>
            <p>Shop</p>
            <p>Customiser</p>
            <p>Cart</p>
            <p>Contact Us</p>
          </div>
        </div>
      </div>
      <div className="grid grid-col-1 md:grid-cols-2 w-full">
        <div className="flex flex-col grid-col-1 items-center justify-items-center md:items-start md:justify-items-end	">
          <img src={nft.image} className="w-2/3 md:w-4/5 mx-auto rounded-xl" />
          <img
            src={nft.gif}
            className="w-2/3 md:w-4/5 mx-auto mt-10 rounded-xl"
          />
        </div>
        <div className="grid-col-1 gap-0 lg:pl-10 flex flex-col items-center mt-10 md:mt-0">
          <div className="w-4/5 px-3 py-1 bg-white shadow-lg rounded-xl">
            <div className="status text-lg text-sedgewear pt-2 ">
              {nft.name}
            </div>
            <div className="actions flex w-full py-3">
              {!connected ? (
                <button
                  onClick={() => connect()}
                  className="py-2 justify-content-center rounded text-white align-items-center flex-1 bg-sedgewear"
                >
                  Connect Wallet
                </button>
              ) : nft.minted ? (
                <button className="py-2 justify-content-center rounded text-white align-items-center flex-1 bg-sedgewear">
                  Already Claimed
                </button>
              ) : (
                <button
                  onClick={() => claimNFT()}
                  className="py-2 justify-content-center rounded text-white align-items-center flex-1 bg-sedgewear"
                >
                  Claim
                </button>
              )}
            </div>
          </div>
          <div className="w-4/5 px-3 py-1 bg-white mt-5 shadow-lg rounded-xl">
            <div className=" bg-white">
              <h1 className=" pt-5 text-xl font-medium text-sedgewear">
                Collection Name
              </h1>
              <div className="py-5 ">
                <h2>{nft.collectionname}</h2>
              </div>
            </div>
          </div>
          <div className="w-4/5 px-3 py-1 bg-white mt-5 shadow-lg rounded-xl">
            <div className=" bg-white">
              <h1 className=" pt-5 text-xl font-medium text-sedgewear">
                Collection Quantity
              </h1>
              <div className="py-5">
                <h2>{nft.volume}</h2>
              </div>
            </div>
          </div>
          <div className="w-4/5 px-3 py-1 bg-white mt-5 shadow-lg rounded-xl">
            <div className=" bg-white">
              <h1 className=" pt-5 text-xl font-medium text-sedgewear">
                Description
              </h1>
              <div className="py-5">
                <h2>{nft.description}</h2>
              </div>
            </div>
          </div>
          <div className="w-4/5 px-3 bg-white mt-5 shadow-lg rounded-xl">
            <h1 className=" pt-5 text-xl font-medium text-sedgewear">
              Download Content
            </h1>
            {connectedWallet == nft.nftowner ? (
              <div className="  flex-wrap justify-around flex py-6">
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-sedgewear rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.gif}`)}
                >
                  Gif
                </button>
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-sedgewear rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.objlow}`)}
                >
                  .OBJ
                </button>

                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-sedgewear rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.gltflow}`)}
                >
                  .GLB
                </button>
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-sedgewear rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.objhigh}`)}
                >
                  .OBJ (HD)
                </button>
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-sedgewear rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.gltfhigh}`)}
                >
                  .GLB(HD)
                </button>
              </div>
            ) : (
              <div className="  flex-wrap justify-around flex py-6">
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-sedgewear rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Content Locked
                </button>
              </div>
            )}
          </div>
          <div
            className="accordion w-4/5 mt-5 shadow-xl"
            id="accordionExample5"
          >
            <div className="accordion-item bg-white w-full">
              <h2
                className="accordion-header bg-white mb-0 rounded-xl"
                id="headingTwo5"
              >
                <button
                  className="
                      accordion-button
                      show
                      relative
                      flex
                      items-center
                      w-full
                      py-4
                      px-5
                      text-base 
                      text-left
                      transition
                      attribute-header
                    "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#attributes"
                  aria-expanded="true"
                  aria-controls="attributes"
                >
                  Attributes
                </button>
              </h2>
              <div
                id="attributes"
                className="accordion-collapse show bg-sedgewear"
                aria-labelledby="attributes-header"
              >
                <div className="accordion-body py-4 px-5 text-white">
                  <div className="grid grid-cols-3 gap-1">
                    {nft.attributes.map((attribute, index) => (
                      <div
                        key={index}
                        className="grid-col-1 flex flex-col p-3 bg-color-third d-flex flex-column border-radius-8px h-100 position-relative attributes-main rounded-lg"
                      >
                        <span className="flex-1 opacity-75 text-xs text-uppercase letter-spacing-3 text-truncate text-secondary">
                          {attribute.trait_type}
                        </span>
                        <span className="fs-14px flex-1 text-sm attribute-value">
                          {attribute.value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer w-full shadow-sm mt-10">
        <div className="container m-auto flex flex-row justify-around items-start text-white mt-10 pb-20 pt-20 gap-0 ">
          <div className="flex flex-col items-start gap-5">
            <p>SUBSCRIBE TO SEDGE VIA EMAIL</p>
            <p className="text-sm text-dark text-gray-400">
              © Copyright Sedgwear.com 2020. All Rights Reserved.
            </p>
            <div className="flex flex-row items-center gap-5">
              <img src={logoWhite} className="w-1/12" />
              <p className="text-sm text-dark text-gray-400">
                © Copyright Sedgwear.com 2020. All Rights Reserved.
              </p>
            </div>
          </div>
          <div className=" flex flex-col items-start gap-5">
            <p>SEDGE BLOG</p>
            <p className="text-sm text-dark text-gray-400">
              Welcome to the Sedge Luxury Swim Short Blog
            </p>
            <div className=" flex flex-row items-center gap-5">
              <p className="text-sm">
                info@sedgewear.com Sedgewear LTD tel.01524 425673{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
