import { useState } from "react";
import { saveAs } from "file-saver";
export default function ContractCreator() {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [error, setError] = useState("");
  const [success, setSucess] = useState("");
  let contract =
    `// SPDX-License-Identifier: MIT

  pragma solidity ^0.8.0;
  
  library SafeMath {
      function tryAdd(uint256 a, uint256 b) internal pure returns (bool, uint256) {
          unchecked {
              uint256 c = a + b;
              if (c < a) return (false, 0);
              return (true, c);
          }
      }
      function trySub(uint256 a, uint256 b) internal pure returns (bool, uint256) {
          unchecked {
              if (b > a) return (false, 0);
              return (true, a - b);
          }
      }
      function tryMul(uint256 a, uint256 b) internal pure returns (bool, uint256) {
          unchecked {
              if (a == 0) return (true, 0);
              uint256 c = a * b;
              if (c / a != b) return (false, 0);
              return (true, c);
          }
      }
      function tryDiv(uint256 a, uint256 b) internal pure returns (bool, uint256) {
          unchecked {
              if (b == 0) return (false, 0);
              return (true, a / b);
          }
      }
      function tryMod(uint256 a, uint256 b) internal pure returns (bool, uint256) {
          unchecked {
              if (b == 0) return (false, 0);
              return (true, a % b);
          }
      }
  
      /**
       * @dev Returns the addition of two unsigned integers, reverting on
       * overflow.
       *
       * Counterpart to Solidity's ` +
    ` operator.
       *
       * Requirements:
       *
       * - Addition cannot overflow.
       */
      function add(uint256 a, uint256 b) internal pure returns (uint256) {
          return a + b;
      }
      function sub(uint256 a, uint256 b) internal pure returns (uint256) {
          return a - b;
      }
      function mul(uint256 a, uint256 b) internal pure returns (uint256) {
          return a * b;
      }
      function div(uint256 a, uint256 b) internal pure returns (uint256) {
          return a / b;
      }
      function mod(uint256 a, uint256 b) internal pure returns (uint256) {
          return a % b;
      }
      function sub(
          uint256 a,
          uint256 b,
          string memory errorMessage
      ) internal pure returns (uint256) {
          unchecked {
              require(b <= a, errorMessage);
              return a - b;
          }
      }
      function div(
          uint256 a,
          uint256 b,
          string memory errorMessage
      ) internal pure returns (uint256) {
          unchecked {
              require(b > 0, errorMessage);
              return a / b;
          }
      }
      function mod(
          uint256 a,
          uint256 b,
          string memory errorMessage
      ) internal pure returns (uint256) {
          unchecked {
              require(b > 0, errorMessage);
              return a % b;
          }
      }
  }
  
  pragma solidity ^0.8.0;
  
  library Strings {
      bytes16 private constant _HEX_SYMBOLS = "0123456789abcdef";
      function toString(uint256 value) internal pure returns (string memory) {
  
          if (value == 0) {
              return "0";
          }
          uint256 temp = value;
          uint256 digits;
          while (temp != 0) {
              digits++;
              temp /= 10;
          }
          bytes memory buffer = new bytes(digits);
          while (value != 0) {
              digits -= 1;
              buffer[digits] = bytes1(uint8(48 + uint256(value % 10)));
              value /= 10;
          }
          return string(buffer);
      }
  
      function toHexString(uint256 value) internal pure returns (string memory) {
          if (value == 0) {
              return "0x00";
          }
          uint256 temp = value;
          uint256 length = 0;
          while (temp != 0) {
              length++;
              temp >>= 8;
          }
          return toHexString(value, length);
      }
      function toHexString(uint256 value, uint256 length) internal pure returns (string memory) {
          bytes memory buffer = new bytes(2 * length + 2);
          buffer[0] = "0";
          buffer[1] = "x";
          for (uint256 i = 2 * length + 1; i > 1; --i) {
              buffer[i] = _HEX_SYMBOLS[value & 0xf];
              value >>= 4;
          }
          require(value == 0, "Strings: hex length insufficient");
          return string(buffer);
      }
  }
  
  pragma solidity ^0.8.0;
  
  library Address {
      function isContract(address account) internal view returns (bool) {
  
          uint256 size;
          assembly {
              size := extcodesize(account)
          }
          return size > 0;
      }
  
      function sendValue(address payable recipient, uint256 amount) internal {
          require(address(this).balance >= amount, "Address: insufficient balance");
  
          (bool success, ) = recipient.call{value: amount}("");
          require(success, "Address: unable to send value, recipient may have reverted");
      }
  
      function functionCall(address target, bytes memory data) internal returns (bytes memory) {
          return functionCall(target, data, "Address: low-level call failed");
      }
      function functionCall(
          address target,
          bytes memory data,
          string memory errorMessage
      ) internal returns (bytes memory) {
          return functionCallWithValue(target, data, 0, errorMessage);
      }
      function functionCallWithValue(
          address target,
          bytes memory data,
          uint256 value
      ) internal returns (bytes memory) {
          return functionCallWithValue(target, data, value, "Address: low-level call with value failed");
      }
      function functionCallWithValue(
          address target,
          bytes memory data,
          uint256 value,
          string memory errorMessage
      ) internal returns (bytes memory) {
          require(address(this).balance >= value, "Address: insufficient balance for call");
          require(isContract(target), "Address: call to non-contract");
  
          (bool success, bytes memory returndata) = target.call{value: value}(data);
          return _verifyCallResult(success, returndata, errorMessage);
      }
      function functionStaticCall(address target, bytes memory data) internal view returns (bytes memory) {
          return functionStaticCall(target, data, "Address: low-level static call failed");
      }
      function functionStaticCall(
          address target,
          bytes memory data,
          string memory errorMessage
      ) internal view returns (bytes memory) {
          require(isContract(target), "Address: static call to non-contract");
  
          (bool success, bytes memory returndata) = target.staticcall(data);
          return _verifyCallResult(success, returndata, errorMessage);
      }
      function functionDelegateCall(address target, bytes memory data) internal returns (bytes memory) {
          return functionDelegateCall(target, data, "Address: low-level delegate call failed");
      }
      function functionDelegateCall(
          address target,
          bytes memory data,
          string memory errorMessage
      ) internal returns (bytes memory) {
          require(isContract(target), "Address: delegate call to non-contract");
  
          (bool success, bytes memory returndata) = target.delegatecall(data);
          return _verifyCallResult(success, returndata, errorMessage);
      }
  
      function _verifyCallResult(
          bool success,
          bytes memory returndata,
          string memory errorMessage
      ) private pure returns (bytes memory) {
          if (success) {
              return returndata;
          } else {
              if (returndata.length > 0) {
  
                  assembly {
                      let returndata_size := mload(returndata)
                      revert(add(32, returndata), returndata_size)
                  }
              } else {
                  revert(errorMessage);
              }
          }
      }
  }
  
  pragma solidity ^0.8.0;
  
  interface IERC721Receiver {
      function onERC721Received(
          address operator,
          address from,
          uint256 tokenId,
          bytes calldata data
      ) external returns (bytes4);
  }
  
  pragma solidity ^0.8.0;
  
  interface IERC165 {
      function supportsInterface(bytes4 interfaceId) external view returns (bool);
  }
  
  pragma solidity ^0.8.0;
  
  abstract contract ERC165 is IERC165 {
      function supportsInterface(bytes4 interfaceId) public view virtual override returns (bool) {
          return interfaceId == type(IERC165).interfaceId;
      }
  }
  
  pragma solidity ^0.8.0;
  
  interface IERC721 is IERC165 {
  
      event Transfer(address indexed from, address indexed to, uint256 indexed tokenId);
      event Approval(address indexed owner, address indexed approved, uint256 indexed tokenId);
      event ApprovalForAll(address indexed owner, address indexed operator, bool approved);
      function balanceOf(address owner) external view returns (uint256 balance);
      function ownerOf(uint256 tokenId) external view returns (address owner);
      function safeTransferFrom(
          address from,
          address to,
          uint256 tokenId
      ) external;
      function transferFrom(
          address from,
          address to,
          uint256 tokenId
      ) external;
      function approve(address to, uint256 tokenId) external;
      function getApproved(uint256 tokenId) external view returns (address operator);
      function setApprovalForAll(address operator, bool _approved) external;
      function isApprovedForAll(address owner, address operator) external view returns (bool);
      function safeTransferFrom(
          address from,
          address to,
          uint256 tokenId,
          bytes calldata data
      ) external;
  }
  
  pragma solidity ^0.8.0;
  
  interface IERC721Enumerable is IERC721 {
      function totalSupply() external view returns (uint256);
      function tokenOfOwnerByIndex(address owner, uint256 index) external view returns (uint256 tokenId);
      function tokenByIndex(uint256 index) external view returns (uint256);
  }
  
  pragma solidity ^0.8.0;
  
  interface IERC721Metadata is IERC721 {
  
      function name() external view returns (string memory);
      function symbol() external view returns (string memory);
      function tokenURI(uint256 tokenId) external view returns (string memory);
  }
  
  pragma solidity ^0.8.0;
  
  abstract contract Context {
      function _msgSender() internal view virtual returns (address) {
          return msg.sender;
      }
  
      function _msgData() internal view virtual returns (bytes calldata) {
          return msg.data;
      }
  }
  
  pragma solidity ^0.8.0;
  
  abstract contract Ownable is Context {
      address private _owner;
  
      event OwnershipTransferred(address indexed previousOwner, address indexed newOwner);
  
      constructor () {
          address msgSender = _msgSender();
          _owner = msgSender;
          emit OwnershipTransferred(address(0), msgSender);
      }
      function owner() public view virtual returns (address) {
          return _owner;
      }
      modifier onlyOwner() {
          require(owner() == _msgSender(), "Ownable: caller is not the owner");
          _;
      }
      function transferOwnership(address newOwner) public virtual onlyOwner {
          require(newOwner != address(0), "Ownable: new owner is the zero address");
          _setOwner(newOwner);
      }
  
      function _setOwner(address newOwner) private {
          address oldOwner = _owner;
          _owner = newOwner;
          emit OwnershipTransferred(oldOwner, newOwner);
      }
  }
  
  pragma solidity ^0.8.0;
  
  contract ERC721 is Context, ERC165, IERC721, IERC721Metadata {
      using Address for address;
      using Strings for uint256;
      string private _name;
      string private _symbol;
      mapping(uint256 => address) private _owners;
      mapping(address => uint256) private _balances;
      mapping(uint256 => address) private _tokenApprovals;
      mapping(address => mapping(address => bool)) private _operatorApprovals;
      string public _baseURI;
      constructor(string memory name_, string memory symbol_) {
          _name = name_;
          _symbol = symbol_;
      }
      function supportsInterface(bytes4 interfaceId) public view virtual override(ERC165, IERC165) returns (bool) {
          return
              interfaceId == type(IERC721).interfaceId ||
              interfaceId == type(IERC721Metadata).interfaceId ||
              super.supportsInterface(interfaceId);
      }
      function balanceOf(address owner) public view virtual override returns (uint256) {
          require(owner != address(0), "ERC721: balance query for the zero address");
          return _balances[owner];
      }
      function ownerOf(uint256 tokenId) public view virtual override returns (address) {
          address owner = _owners[tokenId];
          require(owner != address(0), "ERC721: owner query for nonexistent token");
          return owner;
      }
      function name() public view virtual override returns (string memory) {
          return _name;
      }
      function symbol() public view virtual override returns (string memory) {
          return _symbol;
      }
      function tokenURI(uint256 tokenId) public view virtual override returns (string memory) {
          require(_exists(tokenId), "ERC721Metadata: URI query for nonexistent token");
  
          string memory base = baseURI();
          return bytes(base).length > 0 ? string(abi.encodePacked(base, tokenId.toString())) : "";
      }
      function baseURI() internal view virtual returns (string memory) {
          return _baseURI;
      }
      function approve(address to, uint256 tokenId) public virtual override {
          address owner = ERC721.ownerOf(tokenId);
          require(to != owner, "ERC721: approval to current owner");
  
          require(
              _msgSender() == owner || isApprovedForAll(owner, _msgSender()),
              "ERC721: approve caller is not owner nor approved for all"
          );
  
          _approve(to, tokenId);
      }
      function getApproved(uint256 tokenId) public view virtual override returns (address) {
          require(_exists(tokenId), "ERC721: approved query for nonexistent token");
  
          return _tokenApprovals[tokenId];
      }
      function setApprovalForAll(address operator, bool approved) public virtual override {
          require(operator != _msgSender(), "ERC721: approve to caller");
  
          _operatorApprovals[_msgSender()][operator] = approved;
          emit ApprovalForAll(_msgSender(), operator, approved);
      }
      function isApprovedForAll(address owner, address operator) public view virtual override returns (bool) {
          return _operatorApprovals[owner][operator];
      }
      function transferFrom(
          address from,
          address to,
          uint256 tokenId
      ) public virtual override {
          //solhint-disable-next-line max-line-length
          require(_isApprovedOrOwner(_msgSender(), tokenId), "ERC721: transfer caller is not owner nor approved");
  
          _transfer(from, to, tokenId);
      }
      function safeTransferFrom(
          address from,
          address to,
          uint256 tokenId
      ) public virtual override {
          safeTransferFrom(from, to, tokenId, "");
      }
      function safeTransferFrom(
          address from,
          address to,
          uint256 tokenId,
          bytes memory _data
      ) public virtual override {
          require(_isApprovedOrOwner(_msgSender(), tokenId), "ERC721: transfer caller is not owner nor approved");
          _safeTransfer(from, to, tokenId, _data);
      }
      function _safeTransfer(
          address from,
          address to,
          uint256 tokenId,
          bytes memory _data
      ) internal virtual {
          _transfer(from, to, tokenId);
          require(_checkOnERC721Received(from, to, tokenId, _data), "ERC721: transfer to non ERC721Receiver implementer");
      }
      function _exists(uint256 tokenId) internal view virtual returns (bool) {
          return _owners[tokenId] != address(0);
      }
      function _isApprovedOrOwner(address spender, uint256 tokenId) internal view virtual returns (bool) {
          require(_exists(tokenId), "ERC721: operator query for nonexistent token");
          address owner = ERC721.ownerOf(tokenId);
          return (spender == owner || getApproved(tokenId) == spender || isApprovedForAll(owner, spender));
      }
      function _safeMint(address to, uint256 tokenId) internal virtual {
          _safeMint(to, tokenId, "");
      }
      function _safeMint(
          address to,
          uint256 tokenId,
          bytes memory _data
      ) internal virtual {
          _mint(to, tokenId);
          require(
              _checkOnERC721Received(address(0), to, tokenId, _data),
              "ERC721: transfer to non ERC721Receiver implementer"
          );
      }
      function _mint(address to, uint256 tokenId) internal virtual {
          require(to != address(0), "ERC721: mint to the zero address");
          require(!_exists(tokenId), "ERC721: token already minted");
  
          _beforeTokenTransfer(address(0), to, tokenId);
  
          _balances[to] += 1;
          _owners[tokenId] = to;
  
          emit Transfer(address(0), to, tokenId);
      }
      function _burn(uint256 tokenId) internal virtual {
          address owner = ERC721.ownerOf(tokenId);
  
          _beforeTokenTransfer(owner, address(0), tokenId);
  
          // Clear approvals
          _approve(address(0), tokenId);
  
          _balances[owner] -= 1;
          delete _owners[tokenId];
  
          emit Transfer(owner, address(0), tokenId);
      }
      function _transfer(
          address from,
          address to,
          uint256 tokenId
      ) internal virtual {
          require(ERC721.ownerOf(tokenId) == from, "ERC721: transfer of token that is not own");
          require(to != address(0), "ERC721: transfer to the zero address");
  
          _beforeTokenTransfer(from, to, tokenId);
  
          // Clear approvals from the previous owner
          _approve(address(0), tokenId);
  
          _balances[from] -= 1;
          _balances[to] += 1;
          _owners[tokenId] = to;
  
          emit Transfer(from, to, tokenId);
      }
  
  
      function _approve(address to, uint256 tokenId) internal virtual {
          _tokenApprovals[tokenId] = to;
          emit Approval(ERC721.ownerOf(tokenId), to, tokenId);
      }
      function _checkOnERC721Received(
          address from,
          address to,
          uint256 tokenId,
          bytes memory _data
      ) private returns (bool) {
          if (to.isContract()) {
              try IERC721Receiver(to).onERC721Received(_msgSender(), from, tokenId, _data) returns (bytes4 retval) {
                  return retval == IERC721Receiver(to).onERC721Received.selector;
              } catch (bytes memory reason) {
                  if (reason.length == 0) {
                      revert("ERC721: transfer to non ERC721Receiver implementer");
                  } else {
                      assembly {
                          revert(add(32, reason), mload(reason))
                      }
                  }
              }
          } else {
              return true;
          }
      }
      function _beforeTokenTransfer(
          address from,
          address to,
          uint256 tokenId
      ) internal virtual {}
  }
  
  pragma solidity ^0.8.0;
  
  abstract contract ERC721Enumerable is ERC721, IERC721Enumerable {
      mapping(address => mapping(uint256 => uint256)) private _ownedTokens;
      mapping(uint256 => uint256) private _ownedTokensIndex;
      uint256[] private _allTokens;
      mapping(uint256 => uint256) private _allTokensIndex;
      function supportsInterface(bytes4 interfaceId) public view virtual override(IERC165, ERC721) returns (bool) {
          return interfaceId == type(IERC721Enumerable).interfaceId || super.supportsInterface(interfaceId);
      }
      function tokenOfOwnerByIndex(address owner, uint256 index) public view virtual override returns (uint256) {
          require(index < ERC721.balanceOf(owner), "ERC721Enumerable: owner index out of bounds");
          return _ownedTokens[owner][index];
      }
      function totalSupply() public view virtual override returns (uint256) {
          return _allTokens.length;
      }
      function tokenByIndex(uint256 index) public view virtual override returns (uint256) {
          require(index < ERC721Enumerable.totalSupply(), "ERC721Enumerable: global index out of bounds");
          return _allTokens[index];
      }
      function _beforeTokenTransfer(
          address from,
          address to,
          uint256 tokenId
      ) internal virtual override {
          super._beforeTokenTransfer(from, to, tokenId);
  
          if (from == address(0)) {
              _addTokenToAllTokensEnumeration(tokenId);
          } else if (from != to) {
              _removeTokenFromOwnerEnumeration(from, tokenId);
          }
          if (to == address(0)) {
              _removeTokenFromAllTokensEnumeration(tokenId);
          } else if (to != from) {
              _addTokenToOwnerEnumeration(to, tokenId);
          }
      }
      function _addTokenToOwnerEnumeration(address to, uint256 tokenId) private {
          uint256 length = ERC721.balanceOf(to);
          _ownedTokens[to][length] = tokenId;
          _ownedTokensIndex[tokenId] = length;
      }
      function _addTokenToAllTokensEnumeration(uint256 tokenId) private {
          _allTokensIndex[tokenId] = _allTokens.length;
          _allTokens.push(tokenId);
      }
  
      function _removeTokenFromOwnerEnumeration(address from, uint256 tokenId) private {
  
          uint256 lastTokenIndex = ERC721.balanceOf(from) - 1;
          uint256 tokenIndex = _ownedTokensIndex[tokenId];
          if (tokenIndex != lastTokenIndex) {
              uint256 lastTokenId = _ownedTokens[from][lastTokenIndex];
  
              _ownedTokens[from][tokenIndex] = lastTokenId; 
              _ownedTokensIndex[lastTokenId] = tokenIndex; 
          }
          delete _ownedTokensIndex[tokenId];
          delete _ownedTokens[from][lastTokenIndex];
      }
      function _removeTokenFromAllTokensEnumeration(uint256 tokenId) private {
          uint256 lastTokenIndex = _allTokens.length - 1;
          uint256 tokenIndex = _allTokensIndex[tokenId];
          uint256 lastTokenId = _allTokens[lastTokenIndex];
  
          _allTokens[tokenIndex] = lastTokenId; 
          _allTokensIndex[lastTokenId] = tokenIndex;
          delete _allTokensIndex[tokenId];
          _allTokens.pop();
      }
  }
  
  pragma solidity ^0.8.0;
  
  contract  ${name
    .toUpperCase()
    .replace(/\s/g, "")}  is ERC721Enumerable, Ownable
  {
      using SafeMath for uint256;
      string public artName;
      uint private tokenId=1;
      struct SpecificAddresses{
          address userAddress;
          uint counter;
      }
      struct NewCollection{
          string name;
          uint quantity;
          string description;
      }
      mapping(string => NewCollection) public _newCollection;
      mapping(address => SpecificAddresses) public _whiteList;
      mapping(address=>bool) public _addressExist;
      constructor(string memory baseURI) ERC721("${name}", "${symbol.toUpperCase()}")  {
          setBaseURI(baseURI);
      }
      function setBaseURI(string memory baseURI) public onlyOwner {
          _baseURI = baseURI;
      }
      function addNewCollection(string memory _artName,uint _quantity,string memory _description)public onlyOwner {
            _newCollection[_artName]=NewCollection({
              name :_artName,
              quantity:_quantity,
              description:_description
             });
             artName=_artName;
      }
      function mint(uint _tokenid) public{
          NewCollection storage newArt = _newCollection[artName];
          require(newArt.quantity>0,"Sold Out");
          _safeMint(msg.sender, _tokenid);
          tokenId++;
          newArt.quantity-=1;   
      }
      function totalsupply() private view returns (uint){
          return tokenId;
      }
      function tokensOfOwner(address _owner) public view returns (uint256[] memory){
          uint256 count = balanceOf(_owner);
          uint256[] memory result = new uint256[](count);
          for (uint256 index = 0; index < count; index++) {
              result[index] = tokenOfOwnerByIndex(_owner, index);
          }
          return result;
      }
      function withdraw() public onlyOwner {
          uint balance = address(this).balance;
          payable(msg.sender).transfer(balance);
      }
  }`;

  function downloadFile() {
    if (name == "" || symbol == "") {
      setError("Please Fill all fields");
    }
    const blob = new Blob([contract]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    saveAs(fileDownloadUrl, `${name.toUpperCase()}.sol`);
  }
  return (
    <div className="px-10 container mt-12">
      <h1 className="text-3xl font-medium text-primary">
        Create Smart Contract
      </h1>

      <div className="grid grid-cols-1 w-7/12 mt-5">
        <div class="mb-1">
          <label
            for="base-input"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Contract Name*
          </label>
          <input
            type="text"
            id="base-input"
            class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                focus:bg-white 
                focus:border-purple-[#8C3893]
                focus:outline-none"
            placeholder="Eg: Bitcoin"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div class="mb-1">
          <label
            for="base-input"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Token Symbol*
          </label>
          <input
            type="text"
            id="base-input"
            class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                focus:bg-white 
                focus:border-purple-[#8C3893]
                focus:outline-none"
            placeholder="Eg: BTC"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
          />
        </div>
      </div>
      <span className="text-red-500 text-xl">{error}</span>
      <span className="text-green-700 text-xl">{success}</span>
      <div className="grid grid-cols-1 w-7/12 mt-5 border">
        <button
          onClick={() => downloadFile()}
          className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
        >
          Create Contract
        </button>
      </div>
      <div className="grid grid-cols-1 w-7/12 mt-5 border"></div>
    </div>
  );
}
