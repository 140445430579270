import { useState } from "react";
import "./Login.css";
import logo from "../assets/logo.png";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

export default function ResetPassword() {
  const history = useHistory();
  const { id, token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const reset = () => {
    setError("");
    console.log("reset");
    if (confirmPassword == "" && password == "") {
      setError("Please Fill out both fields");
    } else if (confirmPassword != password) {
      setError("Password did not match");
    } else {
      let obj = {
        id: id,
        token: token,
        password: password,
      };
      axios
        .post(`${REACT_APP_API_URL}/auth/resetPassword`, obj)
        .then((resp) => {
          history.push("/");
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <div className="login-container">
      <div className="flex justify-center">
        <div className="rounded-lg shadow-lg bg-white max-w-md">
          <a data-mdb-ripple="true" data-mdb-ripple-color="light">
            <img className="rounded-t-lg p-10" src={logo} alt="" />
          </a>
          <div className="p-6 ">
            <div className="flex justify-center">
              <div>
                <div className="form-floating mb-3 xl:w-96">
                  <input
                    type="password"
                    className="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="floatingInput"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label htmlFor="floatingInput" className="text-gray-700">
                    Password
                  </label>
                </div>
                <div className="form-floating mb-3 xl:w-96">
                  <input
                    type="password"
                    className="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="floatingPassword"
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <label htmlFor="floatingPassword" className="text-gray-700">
                    Confirm Password
                  </label>
                </div>
                <span className="text-red-500">{error}</span>
                <div className="mb-3 xl:w-96 flex">
                  <button
                    type="button"
                    className="mb-2 w-full inline-block px-6 py-2.5 bg-purple-900 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => reset()}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
