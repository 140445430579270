import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import CsvDownload from "react-json-to-csv";
import jsPDF from "jspdf";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

const Nftlist = () => {
  let { id } = useParams();
  const history = useHistory();
  const [user, setUser] = useState();
  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let authToken = localStorage.getItem("auth-token");
    setUser(user);
    axios
      .get(`${REACT_APP_API_URL}/nft/nfts_of_collection/${id}`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resp) => {
        console.log("resp", resp);
        setNfts(resp.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  async function copyToClickboard(index, uniqueId) {
    // history.push(`https://app.houndstooth.io/claim/${uniqueId}`)
    /* Get the text field */
    let copyText = document.getElementById(`claim${index}`);

    // /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    // let url = "https://app.houndstooth.io/claim/" + uniqueId;
    /* Copy the text inside the text field */
    await navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert("Copied the text: " + copyText.value);
  }

  async function downloadPDF(index, uniqueId) {
    const nft = nfts[index];
    const logo = user.logo;
    const nftImage = nft.image;
    const link = `${user.domainLink}/claim/${nft.uniqueid}/`;
    const metamaskLink = "https://metamask.io";
    console.log(logo, "logo");
    console.log(nftImage, "nftImage");
    console.log(link, "link");

    const doc = new jsPDF();
    doc.addImage(logo, "PNG", 10, 10, 90, 40);
    doc.addImage(nftImage, "PNG", 150, 10, 50, 50);

    doc.setFontSize(12);
    doc.setFont("helvetica");
    doc.setTextColor(100);
    doc.text("Thank you For Purchasing.", 10, 60);
    doc.text(
      "Keep this fIle safe as it contains a unique URL which will allow you to claim your NFT.",
      10,
      68
    );
    doc.text(
      "Please click the link below to CLAIM your NFT. You can do this now or when you are ready and you",
      10,
      76
    );
    doc.text("have your wallet setup.", 10, 81);

    doc.setTextColor(153, 130, 75);
    doc.text(link, 10, 90);

    doc.setTextColor(100);
    doc.text(
      "If you do not have a wallet please signup for one below here.",
      10,
      100
    );

    doc.setTextColor(153, 130, 75);
    doc.text(metamaskLink, 10, 110);

    doc.save();
  }

  return (
    <>
      <div className="pl-10 pr-20 flex flex-col mt-10">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-medium text-primary">
            Existing Collection NFT List
          </h1>
          <div>
            <CsvDownload
              className="bg-primary hover:bg-purple-800 text-white px-4 py-2 rounded-md mr-5"
              data={nfts}
            />
          </div>
        </div>
        <div className=" sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="border-b bg-primary">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-white px-6 py-4 text-left"
                    >
                      Collection Name
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-white px-6 py-4 text-left"
                    >
                      Number
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-white px-6 py-4 text-left"
                    >
                      Minted?
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-white px-6 py-4 text-left"
                    >
                      URI
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-white px-6 py-4 text-left"
                    >
                      Copy
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-white px-6 py-4 text-left"
                    >
                      PDF
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {nfts.map((nft, index) => (
                    <tr key={index} className="border-b bg-white">
                      <td className="px-6 py-4 whitespace-nowrap text-sm  font-bold text-gray-900">
                        {nft.name}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {nft.uniqueid}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {nft.minted ? "Yes" : "No"}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <input
                          type="text"
                          defaultValue={`${user.domainLink}/claim/${nft.uniqueid}`}
                          className="border font-normal  w-20 rounded cursor-pointer focus:outline-none text-primary"
                          id={`claim${index}`}
                        />
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <a
                          onClick={() => copyToClickboard(index, nft.uniqueid)}
                          className="text-primary"
                        >
                          Copy to Clipboard
                        </a>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        <a
                          onClick={() => downloadPDF(index, nft.uniqueid)}
                          className="text-primary"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nftlist;
