import React, { useEffect, useState } from "react";
export default function Home() {
  return (
    <div className="pl-10 pr-20 flex flex-col mt-10">
      <h1 className="text-3xl font-medium text-primary py-4">
        You are not active
      </h1>
    </div>
  );
}
