import { useEffect, useState } from "react";
import "./DashboardLayout.css";
import logo from "../assets/logo.png";
import { useHistory } from "react-router-dom";
export default function DashboardLayout({ children }) {
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);
  const [contractAdded, setContractAdded] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    let data = localStorage.getItem("role");
    console.log(data);
    if (data == "admin") {
      setIsAdmin(true);
    }
    let ct = localStorage.getItem("contractaddress");
    console.log(ct, "CONTRACT ADDRESS");
    if (ct != "undefined") {
      setContractAdded(true);
    }
  }, []);
  function logout() {
    localStorage.clear();
    history.push("/");
  }
  return (
    <div className="font-sans text-gray-900 antialiased">
      <div className="min-h-screen flex bg-gray-200">
        <div className="flex-shrink-0 w-96 bg-grad">
          <a onClick={() => history.push("/dashboard")}>
            <div className="flex items-center h-16 px-4 bg-white text-xl text-white font-medium">
              <img src={logo} />
            </div>
          </a>
          <div className="h-auto flex flex-col justify-between">
            <div className="px-6 py-6 border-t">
              <ul className="flex flex-col list-none">
                {user.active ? (
                  <ul className="mt-3 text-white">
                    <li className="py-2">
                      <a
                        onClick={() => history.push("/dashboard/create")}
                        class="mg-link mg-sidebar-item-link flex content-center justify-between"
                      >
                        <div class="flex content-center items-center cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span
                            class="mg-sidebar-item-link-content  text-white text-sm"
                            style={{ marginLeft: "10px" }}
                          >
                            New Collection
                          </span>
                        </div>
                      </a>
                    </li>
                    <li className="py-2">
                      <a
                        onClick={() => history.push("/dashboard/existing")}
                        class="flex content-center justify-between "
                      >
                        <div class="flex content-center items-center cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                          </svg>
                          <span
                            class="mg-sidebar-item-link-content text-white text-sm"
                            style={{ marginLeft: "10px" }}
                          >
                            Existing Collections
                          </span>
                        </div>
                      </a>
                    </li>
                    {isAdmin ? (
                      <>
                        <li className="py-2">
                          <a
                            onClick={() => history.push("/dashboard/users")}
                            class="flex content-center justify-between"
                          >
                            <div class="flex content-center items-center cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                              </svg>
                              <span
                                class="mg-sidebar-item-link-content text-white text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                User Manager
                              </span>
                            </div>
                          </a>
                        </li>
                        <li className="py-2">
                          <a
                            onClick={() => history.push("/dashboard/staff")}
                            class="flex content-center justify-between"
                          >
                            <div class="flex content-center items-center cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                              </svg>
                              <span
                                class="mg-sidebar-item-link-content text-white text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                Staff Manager
                              </span>
                            </div>
                          </a>
                        </li>
                        <li className="py-2">
                          <a
                            onClick={() =>
                              history.push("/dashboard/contractCreator")
                            }
                            class="flex content-center justify-between"
                          >
                            <div class="flex content-center items-center cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                              </svg>
                              <span
                                class="mg-sidebar-item-link-content text-white text-sm"
                                style={{ marginLeft: "10px" }}
                              >
                                Contract Generator
                              </span>
                            </div>
                          </a>
                        </li>
                      </>
                    ) : null}
                    <li className="py-2">
                      <a
                        onClick={() => history.push("/dashboard/settings")}
                        class="flex content-center justify-between"
                      >
                        <div class="flex content-center items-center cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <span
                            class="mg-sidebar-item-link-content text-white text-sm"
                            style={{ marginLeft: "10px" }}
                          >
                            Settings
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                ) : null}
              </ul>
              <ul className="text-white ">
                <li
                  onClick={() => logout()}
                  className="mt-3 rounded-sm bg-logout text-center py-2 cursor-pointer"
                >
                  <a className="">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex-grow flex flex-col">
          <div className="flex-grow flex flex-col">
            <div className="flex-grow">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
