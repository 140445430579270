import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

const ContractDetails = () => {
  const history = useHistory();
  const [user, setUser] = useState();

  const [address, setAddress] = useState();
  const [abi, setAbi] = useState();

  useEffect(() => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    console.log(user);
    setUser(user);
  }, []);

  function submitDetails() {
    console.log(address, abi);
    if (address == "" || abi == "") {
      alert("Please fill in details");
    } else {
      let authToken = localStorage.getItem("auth-token");
      var body = new FormData();
      body.append("ownerid", user.id);
      body.append("abi", abi);
      body.append("address", address);
      axios
        .post(`${REACT_APP_API_URL}/nft/addContract`, body, {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": authToken,
          },
        })
        .then((resp) => {
          localStorage.setItem("contractaddress", address);
          localStorage.setItem("abi", abi);
          history.push("/dashboard");
          window.location.reload(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  return (
    <>
      <div className="px-10 container mt-12">
        <h1 className="text-3xl font-medium text-primary">Contract Details</h1>

        <div className="grid grid-cols-1 w-7/12 mt-5">
          <div className="mb-6">
            <label
              htmlFor="base-input"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Contract Address
            </label>
            <input
              onPaste={(e) => {
                let value = e.clipboardData.getData("Text");
                navigator.clipboard.writeText("");
                setAddress(value);
              }}
              type="text"
              id="base-input"
              className="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
            />
          </div>
          <label
            htmlFor="base-input"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Contract Abi
          </label>
          <textarea
            name="ctl00$ContentPlaceHolder1$txtSourceCode"
            rows="100"
            cols="200"
            id="ContentPlaceHolder1_txtSourceCode"
            className="wordwrap px-5 form-control-sm w-100 mb-1 rounded-xl h-96 focus:ring-blue-500 focus:border-blue-500"
            height="200px"
            spellCheck="false"
            required=""
            data-msg="Required"
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            onPaste={(e) => {
              let value = e.clipboardData.getData("Text");
              navigator.clipboard.writeText("");
              setAbi(value);
            }}
          ></textarea>
        </div>
        <div className="grid grid-cols-1 w-7/12 mt-5 border">
          <button
            onClick={() => submitDetails()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
          >
            Submit Details
          </button>
        </div>
      </div>
    </>
  );
};

export default ContractDetails;
