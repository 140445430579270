import { useEffect, useState } from "react";
import axios from "axios";
import S3FileUpload from "react-s3";
import { useHistory, useParams } from "react-router-dom";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

export default function AdminEditUser() {
  let { id } = useParams();
  const history = useHistory();

  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    let authToken = localStorage.getItem("auth-token");
    axios
      .get(`${REACT_APP_API_URL}/auth/getUserData/${id}`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((resp) => {
        console.log(resp.data, "USER DATA");
        setUser(resp.data);
        setName(resp.data.name);
      });
  }, []);

  async function updateUser() {
    setSuccess("");
    setError("");
    if (name == "") {
      setError("Please fill all fields");
    } else {
      let obj = {
        email: user.email,
        name: name,
        companyname: user.companyname,
        telephone: user.telephone,
        companyaddress1: user.companyaddress1,
        companyaddress2: user.companyaddress2,
        companyaddress3: user.companyaddress3,
        postcode: user.postcode,
        logo: user.logo,
        summary: user.summary,
      };
      let authToken = localStorage.getItem("auth-token");
      axios
        .post(`${REACT_APP_API_URL}/auth/AdminEditUser`, obj, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((resp) => {
          history.push("/dashboard/staff");
          console.log("resp", resp);
        })
        .catch((error) => {
          setError("An Error Occured");
          console.log("error", error);
        });
    }
  }

  return (
    <>
      <div className="px-10 container mt-12">
        <h1 className="text-3xl font-medium text-primary">Edit Staff</h1>

        <div className="grid grid-cols-1 w-7/12 mt-5">
          <div class="mb-6">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Name
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                      focus:bg-white 
                      focus:border-purple-[#8C3893]
                      focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <span className="text-red-500 text-xl">{error}</span>
        <span className="text-green-700 text-xl">{success}</span>
        <div className="grid grid-cols-1 w-7/12 mt-5 border">
          <button
            onClick={() => updateUser()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
          >
            Update Profile
          </button>
        </div>
        <div className="grid grid-cols-1 w-7/12 mt-5 border"></div>
      </div>
    </>
  );
}
