import React, { useEffect, useState } from "react";
import axios from "axios";
import S3FileUpload from "react-s3";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

const CreateUser = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyLine1, setCompanyLine1] = useState("");
  const [companyLine2, setCompanyLine2] = useState("");
  const [companyLine3, setCompanyLine3] = useState("");
  const [postCode, setPostCode] = useState("");
  const [logo, setLogo] = useState(null);
  const [summary, setSummary] = useState("");
  const [domainLink, setDomainLink] = useState("");
  const [plan, setPlan] = useState("0");
  const [contractNetwork, setContractNetwork] = useState("Ethereum");

  const [address, setAddress] = useState(null);
  const [abi, setAbi] = useState(null);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  async function createUser() {
    setSuccess("");
    setError("");
    if (
      name == "" ||
      email == "" ||
      password == "" ||
      confirmPassword == "" ||
      companyName == "" ||
      companyLine1 == "" ||
      postCode == "" ||
      logo == null ||
      plan == "0" ||
      domainLink == ""
    ) {
      setError("Please fill all fields");
    } else if (password != confirmPassword) {
      setError("Password did not match");
    } else {
      setSuccess("Uploading Data");
      let image = await uploadToS3(logo);
      let obj = {
        email: email,
        role: "user",
        password: password,
        plan: plan,
        name: name,
        companyname: companyName,
        telephone: telephone,
        companyaddress1: companyLine1,
        companyaddress2: companyLine2,
        companyaddress3: companyLine3,
        postcode: postCode,
        logo: image.location,
        summary: summary,
        domainLink: domainLink
      };
      let authToken = localStorage.getItem("auth-token");
      axios
        .post(`${REACT_APP_API_URL}/auth/createUser`, obj, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((resp) => {
          setName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setPlan("0");
          var body = new FormData();
          body.append("ownerid", resp.data.id);
          body.append("abi", abi);
          body.append("address", address);
          body.append("contractNetwork", contractNetwork);
          if (abi != null && address != null) {
            axios
              .post(`${REACT_APP_API_URL}/nft/addContract`, body, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "auth-token": authToken,
                },
              })
              .then((resp) => {
                localStorage.setItem("contractaddress", address);
                localStorage.setItem("abi", abi);
                setSuccess("User Created");
              })
              .catch((error) => {
                console.log("error", error);
              });
          } else {
            setSuccess("User Created");
          }
        })
        .catch((error) => {
          setError("Email already exists");
          console.log("error", error);
        });
    }
  }

  function imageInput(event) {
    console.log(event.target.files[0]);
    setLogo(event.target.files[0]);
  }
  function uploadToS3(file) {
    const config = {
      bucketName: "houndstooth-app",
      dirName: "files",
      region: "eu-west-2",
      accessKeyId: "AKIA5SWLXHYBSC3YZFUY",
      secretAccessKey: "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",
    };
    return S3FileUpload.uploadFile(file, config);
  }

  return (
    <>
      <div className="px-10 container mt-12">
        <h1 className="text-3xl font-medium text-primary">Create Users</h1>

        <div className="grid grid-cols-1 w-7/12 mt-5">
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Name*
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Name*
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="mb-1 flex w-full">
            <div class="mb-1 mr-2 w-full">
              <label
                for="base-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Telephone*
              </label>
              <input
                type="text"
                id="base-input"
                class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
            <div class="mb-1 ml-2 w-full">
              <label
                for="base-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Email*
              </label>
              <input
                type="email"
                id="base-input"
                class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Password*
            </label>
            <input
              type="password"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Confirm Password*
            </label>
            <input
              type="password"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Line Address 1*
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={companyLine1}
              onChange={(e) => setCompanyLine1(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Line Address 2
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={companyLine2}
              onChange={(e) => setCompanyLine2(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Line Address 3
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={companyLine3}
              onChange={(e) => setCompanyLine3(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Postalcode*
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
            />
          </div>
          <div class="mb-1">
            <label
              for="base-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Company Summary*
            </label>
            <input
              type="text"
              id="base-input"
              class="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
          </div>
          <div className=" flex w-full">
            <div className="grid grid-cols-1 mr-2 mb-5 border">
              <label
                for="base-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Company Logo*
              </label>
              <input
                className="block w-full px-3 py-2.5  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="user_avatar_help"
                id="user_avatar"
                type="file"
                onChange={(event) => imageInput(event)}
              />
            </div>
            <div class="flex justify-start ml-2 mt-8">
              <div class="mb-1 xl:w-96">
                <select
                  class="form-select appearance-none
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                  value={plan}
                  onChange={(e) => setPlan(e.target.value)}
                >
                  <option value="0" selected>
                    Select User Plan*
                  </option>
                  <option value="1">Plan One (1-200 NFT month limit)</option>
                  <option value="2">Plan Two (201- 500 NFT month limit)</option>
                  <option value="3">
                    Plan Three (501 - Unlimited NFT per month)
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div className="mb-1">
            <label
                htmlFor="base-input"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Domain Link*
            </label>
            <input
                type="text"
                id="base-input"
                className="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700
                    focus:bg-white
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
                value={domainLink}
                onChange={(e) => setDomainLink(e.target.value)}
            />
          </div>

          <div>
            <div className="mb-1">
              <label htmlFor="base-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Contract Network
              </label>
              <div className="flex justify-start">
                <div className="mb-1 xl:w-full">
                  <select
                      className="form-select appearance-none
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Default select example"
                      value={contractNetwork}
                      onChange={(e) => setContractNetwork(e.target.value)}
                  >
                    <option value="Etherium">Etheruum</option>
                    <option value="Polygon">Ploygon</option>
                  </select>
                </div>
              </div>

            </div>
            <div className="mb-1">
              <label
                htmlFor="base-input"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Contract Address
              </label>
              <input
                onPaste={(e) => {
                  let value = e.clipboardData.getData("Text");
                  navigator.clipboard.writeText("");
                  setAddress(value);
                }}
                type="text"
                id="base-input"
                className="bg-gray-50 border py-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 focus:text-gray-700 
                    focus:bg-white 
                    focus:border-purple-[#8C3893]
                    focus:outline-none"
              />
            </div>
            <label
              htmlFor="base-input"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Contract Abi
            </label>
            <textarea
              name="ctl00$ContentPlaceHolder1$txtSourceCode"
              rows="100"
              cols="67"
              id="ContentPlaceHolder1_txtSourceCode"
              className="wordwrap px-5 form-control-sm w-100 mb-1 rounded-xl h-96 focus:ring-blue-500 focus:border-blue-500"
              height="200px"
              spellCheck="false"
              required=""
              data-msg="Required"
              data-error-class="u-has-error"
              data-success-class="u-has-success"
              onPaste={(e) => {
                let value = e.clipboardData.getData("Text");
                navigator.clipboard.writeText("");
                setAbi(value);
              }}
            ></textarea>
          </div>
        </div>
        <span className="text-red-500 text-xl">{error}</span>
        <span className="text-green-700 text-xl">{success}</span>
        <div className="grid grid-cols-1 w-7/12 mt-5 border">
          <button
            onClick={() => createUser()}
            className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-gray-900"
          >
            Create User
          </button>
        </div>
        <div className="grid grid-cols-1 w-7/12 mt-5 border"></div>
      </div>
    </>
  );
};

export default CreateUser;
