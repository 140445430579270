import React, { useState, useEffect } from "react";
import "./ClaimNFT.css";
import axios from "axios";
import Web3 from "web3";
import { useParams } from "react-router-dom";
import HoundsClaim from "./ClaimPages/HoundsClaim";
import SedgewearClaim from "./ClaimPages/SedgewearClaim";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

export default function ClaimNFT() {
  const { id } = useParams();
  const [nft, setNft] = useState();
  const [contractInfo, setContractInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [contract, setContract] = useState(null);
  const [connected, setConnected] = useState(false);
  const [connectedWallet, setConnectedWallet] = useState(false);
  const [user, setUser] = useState();
  const [owner, setOwner] = useState();

  useEffect(async () => {
    console.log(window.location.host);
    console.log(id);
    let authToken = localStorage.getItem("auth-token");
    let user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    await axios
      .get(`${REACT_APP_API_URL}/nft/${id}`, {
        headers: { "auth-token": authToken },
      })
      .then((resp) => {
        setNft(resp.data[0]);
        console.log(resp.data[0]);
        axios
          .get(
            `${REACT_APP_API_URL}/nft/get_contract_of_owner/${resp.data[0].collectionowner}`
          )
          .then((resp) => {
            setContractInfo(resp.data);
            loadWeb3(resp.data);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  async function loadWeb3(info) {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      // await window.ethereum.enable();
      const web3 = window.web3;
      // creating contract instance
      const contractaddress = info.address;
      const abi = JSON.parse(info.abi);
      const ct = new web3.eth.Contract(abi, contractaddress);
      console.log(ct);
      setContract(ct);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }
  async function connect() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      console.log(metaMaskAccount);
      setConnectedWallet(metaMaskAccount[0]);
      setConnected(true);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  const claimNFT = async () => {
    let tokenid = nft.nftnumber;
    console.log(tokenid);
    let authToken = localStorage.getItem("auth-token");

    const web3 = window.web3;
    const address = await web3.eth.getAccounts();
    await contract.methods
      .mint(parseInt(tokenid))
      .send({
        from: address.toString(),
      })
      .then(async (resp) => {
        console.log(resp);
        let obj = {
          nftid: id,
          owner: connectedWallet,
        };
        axios
          .post(`${REACT_APP_API_URL}/nft/claim`, obj, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then(async (resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  if (isLoading) {
    return <h1>Loading</h1>;
  } else if (window.location.host == "nft.sedgewear.com") {
    // } else if (window.location.host == "localhost:3000") {
    return (
      <SedgewearClaim
        nft={nft}
        connect={connect}
        connected={connected}
        claimNFT={claimNFT}
        connectedWallet={connectedWallet}
      />
    );
  } else {
    return (
      <HoundsClaim
        nft={nft}
        connect={connect}
        connected={connected}
        claimNFT={claimNFT}
        connectedWallet={connectedWallet}
      />
    );
  }
}
