import "./HoundsClaim.css";
export default function HoundsClaim({
  nft,
  connect,
  claimNFT,
  connected,
  connectedWallet,
}) {
  return (
    <div className=" bg-gray-100">
      <div className="grid grid-col-1 md:grid-cols-2 w-full pt-28">
        <div className="flex flex-col grid-col-1 items-center justify-items-center md:items-start md:justify-items-end	">
          <img src={nft.image} className="w-2/3 md:w-4/5 mx-auto rounded-xl" />
          <img
            src={nft.gif}
            className="w-2/3 md:w-4/5 mx-auto mt-10 rounded-xl"
          />
        </div>

        <div className="grid-col-1 gap-0  lg:pl-10 ">
          <div className="w-4/5 px-3 py-1 bg-white shadow-lg rounded-xl">
            <div className="status text-lg pt-2 ">{nft.name}</div>
            <div className="actions flex w-full py-3">
              {!connected ? (
                <button
                  onClick={() => connect()}
                  className="py-2 justify-content-center rounded text-white align-items-center flex-1 bg-primary"
                >
                  Connect Wallet
                </button>
              ) : nft.minted ? (
                <button className="py-2 justify-content-center rounded text-white align-items-center flex-1 bg-primary">
                  Already Claimed
                </button>
              ) : (
                <button
                  onClick={() => claimNFT()}
                  className="py-2 justify-content-center rounded text-white align-items-center flex-1 bg-primary"
                >
                  Claim
                </button>
              )}
            </div>
          </div>
          <div className="w-4/5 px-3 py-1 bg-white mt-5 shadow-lg rounded-xl">
            <div className=" bg-white">
              <h1 className="text-primary pt-5 text-xl font-medium">
                Collection Name
              </h1>
              <div className="py-5">
                <h2>{nft.collectionname}</h2>
              </div>
            </div>
          </div>
          <div className="w-4/5 px-3 py-1 bg-white mt-5 shadow-lg rounded-xl">
            <div className=" bg-white">
              <h1 className="text-primary pt-5 text-xl font-medium">
                Collection Quantity
              </h1>
              <div className="py-5">
                <h2>{nft.volume}</h2>
              </div>
            </div>
          </div>
          <div className="w-4/5 px-3 py-1 bg-white mt-5 shadow-lg rounded-xl">
            <div className=" bg-white">
              <h1 className="text-primary pt-5 text-xl font-medium">
                Description
              </h1>
              <div className="py-5">
                <h2>{nft.description}</h2>
              </div>
            </div>
          </div>
          <div className="w-4/5 px-3 bg-white mt-5 shadow-lg rounded-xl">
            <h1 className="text-primary pt-5 text-xl font-medium">
              Download Content
            </h1>
            {connectedWallet == nft.nftowner ? (
              <div className="  flex-wrap justify-around flex py-6">
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-primary rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.gif}`)}
                >
                  Gif
                </button>
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-primary rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.objlow}`)}
                >
                  .OBJ
                </button>

                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-primary rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.gltflow}`)}
                >
                  .GLB
                </button>
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-primary rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.objhigh}`)}
                >
                  .OBJ (HD)
                </button>
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-primary rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  onClick={() => window.open(`${nft.gltfhigh}`)}
                >
                  .GLB(HD)
                </button>
              </div>
            ) : (
              <div className="  flex-wrap justify-around flex py-6">
                <button
                  type="button"
                  className="inline-block px-6 py-2.5 bg-primary rounded-2xl text-white font-medium text-xs leading-tight uppercase  shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Content Locked
                </button>
              </div>
            )}
          </div>
          <div
            className="accordion w-4/5 mt-5 shadow-xl"
            id="accordionExample5"
          >
            <div className="accordion-item bg-white w-full">
              <h2
                className="accordion-header bg-white mb-0 rounded-xl"
                id="headingTwo5"
              >
                <button
                  className="
                    accordion-button
                    show
                    relative
                    flex
                    items-center
                    w-full
                    py-4
                    px-5
                    text-base text-white text-left
                    focus:text-black
                    active:text-black
                    focus-within:text-black
                    bg-primary
                    transition
                  "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#attributes"
                  aria-expanded="true"
                  aria-controls="attributes"
                >
                  Attributes
                </button>
              </h2>
              <div
                id="attributes"
                className="accordion-collapse show bg-primary"
                aria-labelledby="attributes-header"
              >
                <div className="accordion-body py-4 px-5 text-white">
                  <div className="grid grid-cols-3 gap-1">
                    {nft.attributes.map((attribute, index) => (
                      <div
                        key={index}
                        className="grid-col-1 flex flex-col p-3 bg-color-third d-flex flex-column border-radius-8px h-100 position-relative attributes-main rounded-lg"
                      >
                        <span className="flex-1 opacity-75 text-xs text-uppercase letter-spacing-3 text-truncate text-secondary">
                          {attribute.trait_type}
                        </span>
                        <span className="fs-14px flex-1 text-sm attribute-value">
                          {attribute.value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
