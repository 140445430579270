import { useHistory } from "react-router-dom";
export default function Setting() {
  const history = useHistory();
  return (
    <div className="px-10 container mt-12">
      <h1 className="text-3xl font-medium text-primary">Settings</h1>
      <div className="grid grid-cols-1 w-7/12 mt-5 border">
        <button
          onClick={() => history.push("/dashboard/edit")}
          className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-purple-800"
        >
          Edit Details
        </button>
        <button
          onClick={() => history.push("/dashboard/contract")}
          className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-purple-800"
        >
          Contract Details
        </button>
        <button
          onClick={() => history.push("/dashboard/update-password")}
          className="w-full py-2.5 border-red-50 border rounded-lg bg-primary text-white cursor hover:bg-purple-800"
        >
          Change Password
        </button>
      </div>
    </div>
  );
}
