import { Route, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
export default function ProtectedRoute({ children, ...rest }) {
  let auth = localStorage.getItem("auth-token");
  let [user, setUser] = useState();
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    setUser(user);
    setLoading(false);
  }, []);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
