import React, { useEffect, useState } from "react";
import "./Existing.css";
import { useHistory } from "react-router-dom";
import axios from "axios";

require("dotenv").config();
const { REACT_APP_API_URL } = process.env;

const Existing = () => {
	const history = useHistory();
	const [user, setUser] = useState();
	const [collections, setCollections] = useState([]);

	function openList(id) {
		history.push(`/dashboard/existing/nft/${id}`);
	}
	useEffect(() => {
		let user = JSON.parse(localStorage.getItem("user"));
		let authToken = localStorage.getItem("auth-token");
		setUser(user);
		axios
			.get(`${REACT_APP_API_URL}/nft/collections/${user.id}`, {
				headers: {
					"auth-token": authToken,
				},
			})
			.then((resp) => {
				console.log("resp", resp);
				setCollections(resp.data);
			})
			.catch((error) => {
				console.log("error", error);
			});
	}, []);

	return (
		<>
			<div className="pl-10 pr-20 flex flex-col mt-10">
				<h1 className="text-3xl font-medium text-primary py-4">
					Existing Collection
				</h1>
				<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow-md sm:rounded-lg">
							<table className="min-w-full">
								<thead className="border-b bg-primary">
									<tr>
										<th
											scope="col"
											className="text-sm font-medium text-white px-6 py-4 text-left"
										>
											Collection Name
										</th>
										<th
											scope="col"
											className="text-sm font-medium text-white px-6 py-4 text-left"
										>
											Volume
										</th>
										<th
											scope="col"
											className="text-sm font-medium text-white px-6 py-4 text-left"
										>
											List NFTs
										</th>
										<th
											scope="col"
											className="text-sm font-medium text-white px-6 py-4 text-left"
										>
											View On
										</th>
									</tr>
								</thead>
								<tbody>
									{collections.map((collection, index) => (
										<tr key={index} className="border-b bg-white">
											<td className="px-6 py-4 whitespace-nowrap text-sm  font-medium text-gray-900">
												{collection.name}
											</td>
											<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
												{collection.volume}
											</td>
											<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
												<a
													onClick={() => openList(collection.id)}
													className="text-primary"
												>
													List
												</a>
											</td>
											<td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
												<a href="" className="text-primary">
													View On Etherscan
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Existing;
